@import 'antd/dist/antd.css';
/* 项目字体图标 */
@font-face {
font-family: 'iconfont';
src: url('https://at.alicdn.com/t/font_2506431_ik6uuy0mx6d.ttf?t=1634001509799');
}
.iconfont{font-family: "iconfont" !important;}


.header{
  /* background-color: #187aff; */
  width:70%;
  padding: 10px 0;
  margin-left:auto;
  margin-right:auto;
}
.footer{
  width:70%;
  /* box-sizing: border-box; */
  padding: 20px 0px;
  color: #fff;
  margin-left:auto;
  margin-right:auto;
}
.main-box{
  width:70%;
  background-color: #fff;
  color: #000;
  padding: 30px 50px;
  margin-left:auto;
  margin-right:auto;
  line-height: 28px;
  font-size: 16px;
}
.left-title-box{
  font-size: 13px;
  width: 250px;
  height: 100%;
  padding: 5px 5px 5px 0;
  box-shadow: 0px 2px 12px 0 rgba(0, 0, 0, 0.1)
}
.title-item{
  padding: 14px 20px;
  border-left: 5px solid #fff;
  cursor: pointer;
}
.title-item:hover{
  background-color: #f7f7f7;
}

.title-item-active{
  background-color: #f7f7f7;
  font-weight: bold;
  border-left: 5px solid #187aff;
}
.hover-content{
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  padding: 20px;
  color: #fff;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  display: none;
  overflow: auto;
}
.dx:hover .hover-content{
  display: inline-block;
}
.auto-img{
  width: 100%;
  height: auto;
}
  

.dx-img{
  overflow: hidden;
}
.dx-img img{
  cursor: pointer;
  transition: all 0.6s;
}
.dx-img img:hover {
  transform: scale(1.4);
}

.easy-in{
  animation: fadeInAnimation ease 1s; 
  animation-iteration-count: 1; /*设置动画播放次数*/
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation { 
  0% { 
      opacity: 0; /*设置不透明度*/
  } 
  100% { 
      opacity: 1; 
  } 
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px dashed rgba(0, 0, 0, 0.5);
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 5px;
  /* background-color: rgba(0, 0, 0, .4); */
  background-color: #f7f7f7;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
  background-color: #187aff;
}

/* 基础样式类 */
.f12{font-size: 12px;}
.f16{font-size: 16px;}
.f18{font-size: 18px;}
.f20{font-size: 20px;}
.f22{font-size: 22px;}
.f24{font-size: 24px;}
.f26{font-size: 26px;}
.f28{font-size: 28px;}
.f42{font-size: 42px;}

.b{font-weight: bold;}
.ac{text-align: center;}
.ar{text-align: right;}
.fc{margin-left:auto;margin-right:auto;}

.inline-block{display: inline-block;}
.flex{display: flex;}
.flex1{flex: 1;}
.flex-center{align-items: center;}

.mt5{margin-top: 5px;}
.mt10{margin-top: 10px;}
.mt15{margin-top: 15px;}
.mt20{margin-top: 20px;}
.mt25{margin-top: 25px;}
.mt30{margin-top: 30px;}

.mb5{margin-bottom: 5px;}
.mb10{margin-bottom: 10px;}
.mb15{margin-bottom: 15px;}
.mb20{margin-bottom: 20px;}
.mb25{margin-bottom: 25px;}
.mb30{margin-bottom: 30px;}
.mb40{margin-bottom: 40px;}

.mtb5{margin-top: 5px;margin-bottom: 5px;}
.mtb10{margin-top: 10px;margin-bottom: 10px;}
.mtb15{margin-top: 15px;margin-bottom: 15px;}
.mtb20{margin-top: 20px;margin-bottom: 20px;}
.mtb25{margin-top: 25px;margin-bottom: 25px;}
.mtb30{margin-top: 30px;margin-bottom: 30px;}

.mlr5{margin-left: 5px;margin-right: 5px;}
.mlr10{margin-left: 10px;margin-right: 10px;}
.mlr15{margin-left: 15px;margin-right: 15px;}
.mlr20{margin-left: 20px;margin-right: 20px;}
.mlr25{margin-left: 25px;margin-right: 25px;}
.mlr30{margin-left: 30px;margin-right: 30px;}

.ml5{margin-left: 5px;}
.ml10{margin-left: 10px;}
.ml15{margin-left: 15px;}
.ml20{margin-left: 20px;}
.ml25{margin-left: 25px;}
.ml30{margin-left: 30px;}
.ml50{margin-left: 50px;}


.mr5{margin-right: 5px;}
.mr10{margin-right: 10px;}
.mr15{margin-right: 15px;}
.mr20{margin-right: 20px;}
.mr25{margin-right: 25px;}
.mr30{margin-right: 30px;}

.pt5{padding-top: 5px;}
.pt10{padding-top: 10px;}
.pt15{padding-top: 15px;}
.pt20{padding-top: 20px;}
.pt25{padding-top: 25px;}
.pt30{padding-top: 30px;}

.pb5{padding-bottom: 5px;}
.pb10{padding-bottom: 10px;}
.pb15{padding-bottom: 15px;}
.pb20{padding-bottom: 20px;}
.pb25{padding-bottom: 25px;}
.pb30{padding-bottom: 30px;}

.plr5{padding-left: 5px;padding-right: 5px;}
.plr10{padding-left: 10px;padding-right: 10px;}
.plr15{padding-left: 15px;padding-right: 15px;}
.plr20{padding-left: 20px;padding-right: 20px;}
.plr25{padding-left: 25px;padding-right: 25px;}
.plr30{padding-left: 30px;padding-right: 30px;}

.p10{padding: 10px;}
.p15{padding: 15px;}
.p20{padding: 20px;}
.p25{padding: 25px;}
.p30{padding: 30px;}

.fwh150{width: 150px;height: 150px;}
.fwh200{width: 200px;height: 200px;}

.bg-f7{background-color: #f7f7f7;}
.bg-blue{background-color: #187aff;color: #fff;}
.bg-white{background-color: #fff;color: #000;}

/* 颜色 */
.text-white{color: #fff;}
.text-blue{color: #187aff;}
.text-black{color: #000;}

/* 阴影 */
.shadow-gray{box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)}

/* 手势 */
.pointer{cursor: pointer;}
.no-select{user-select: none;}

/* 省略 */
.of{height:24px;overflow:hidden;-o-text-overflow:ellipsis;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical}
.of2{height:40px;-webkit-line-clamp:2}
.of3{height:85px;-webkit-line-clamp:3}

/* 百分比布局 */
.clear{clear:both}
.clear:after{content:" ";display:block;clear:both}

.w10,.w20,.w25,.w30,.w33,.w40,.w50,.w60,.w66,.w70,.w75,.w80,.w90,.w100{float:left;display:block}
.w10{width:10%}
.w20{width:20%}
.w25{width:25%}
.w30{width:30%}
.w33{width:33.33%}
.w40{width:40%}
.w50{width:50%}
.w60{width:60%}
.w66{width:66%}
.w70{width:70%}
.w75{width:75%}
.w80{width:80%}
.w90{width:90%}
.w100{width:100%}

/* 排版 */
.relative{position:relative}
.absolute{position:absolute}
.absolute-center{
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-60%)
}

.vm{vertical-align: middle;}