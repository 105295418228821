.dx .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected{
    border-bottom: 2px solid #fff;
}
.dx .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected{
    color:#fff;
}
.dx .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover{
    color: #187aff;
}
.dx .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after{
    /* border-bottom: 2px solid #fff; */
    border-bottom: none;
}
.dx .ant-menu-horizontal {
    line-height: 46px;
    border: 0;
    border-bottom: 1px solid #187aff;
    box-shadow: none;
}
.ant-menu-submenu-popup > .ant-menu {
    background-color: #187aff;
    color: #fff;
}
.ant-menu-light .ant-menu-item:hover{
    background-color: #fff;
    color: #187aff;
}
/* .dx .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover{
    background: #fff;
} */
.dx .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{
    color:#fff;
}
.dx .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after{
    border-bottom: 2px solid #fff;
}
.dx .ant-menu-horizontal > .ant-menu-item a{
    color: #fff;
}
.dx .ant-menu-horizontal > .ant-menu-item:hover a{
    color: #187aff;
}
/* 二级路由样式 */
.ant-menu-item a{
    color: #fff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: #fff;
}
.ant-menu-item-selected a{
    color: #187aff;
}